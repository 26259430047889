// Copyright 2022, Imprivata, Inc.  All rights reserved.

import { InputBox } from '@imprivata-cloud/components';
import { Col, Form, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useFetchOrgPreferences } from './store/hooks';
import SaveButton from '../../../components/page-layout/action-bar/SaveButton';
import CancelButton from '../../../components/page-layout/action-bar/CancelButton';
import HelpButton from '../../../components/page-layout/action-bar/HelpButton';
import SaveDiscardModal from '../../modals/save-discard-modal/SaveDiscardModal';
import { SettingsPageLayout } from '../components';
import classes from './Appearance.module.less';
import LogoUploader from './LogoUploader';
import { loadOrgPreferencesSuccess } from './store/actions';
import { SUCCESS_ORG_PREFERENCES } from './store/constants';
import { type OrgPreferences } from './store/reducers';
import { updateOrgPreferences } from '../../../api/preferencesService';
import getConfig from '../../../appConfigUtils';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const updatePreferences = async (
  // eslint-disable-next-line
  dispatch: Dispatch<any>,
  passwordLabel?: string,
  usernameLabel?: string,
  proxCardLabel?: string,
  orgName?: string,
  logoUrl?: string,
) => {
  await updateOrgPreferences({
    logoUrl,
    passwordLabel,
    orgName,
    usernameLabel,
    proxCardLabel,
  });
  dispatch(
    loadOrgPreferencesSuccess({
      data: {
        orgName,
        logoUrl,
        usernameLabel,
        passwordLabel,
        proxCardLabel,
      },
      operations: SUCCESS_ORG_PREFERENCES,
    }),
  );
};

const requiredMsgKey = 'appearance.organization.customization.label.required';

const { EPCS_ENABLED } = getConfig();

const orgPreferencesFormData = (formData: OrgPreferences) => {
  return {
    orgName: formData?.orgName,
    passwordLabel: formData?.passwordLabel,
    usernameLabel: formData?.usernameLabel,
    proxCardLabel: formData?.proxCardLabel,
  };
};

const Appearance: React.FC = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useFetchOrgPreferences();
  const [uploadedUrl, setUploadedUrl] = useState('');
  const [form] = Form.useForm<OrgPreferences>();
  const [isSaveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [reset, setReset] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const dispatch = useDispatch();

  // placeholders
  const usernameDefault = t(
    'appearance.organization.authentication.username.placeholder',
  );
  const passwordDefault = t(
    'appearance.organization.authentication.password.placeholder',
  );
  const proxcardDefault = t(
    'appearance.organization.authentication.prox-card.placeholder',
  );

  const resetForm = () => {
    if (isDirty) {
      form.setFieldsValue(orgPreferencesFormData(data));
      setReset(!reset);
    }
    setSaveDisabled(true);
    setIsDirty(false);
    setIsModalVisible(false);
  };

  const handleChangeText = () => {
    setSaveDisabled(false);
    setIsDirty(true);
    setIsModalVisible(true);
    form.validateFields().catch(() => {
      setSaveDisabled(true);
      setIsDirty(false);
      setIsModalVisible(false);
    });
  };

  // When the user wants to leave without saving
  useEffect(() => {
    if (isDirty) {
      setIsModalVisible(true);
    }
  }, [isDirty]);

  useEffect(() => {
    form.setFieldsValue(orgPreferencesFormData(data));
  }, [data, form]);

  useEffect(() => {
    if (data && data.logoUrl) {
      setUploadedUrl(data.logoUrl);
    }
  }, [data]);

  const validateAndUpdate = () => {
    setIsDirty(false);
    const passwordLabel = form.getFieldValue('passwordLabel') as string;
    const usernameLabel = form.getFieldValue('usernameLabel') as string;
    const proxCardLabel = form.getFieldValue('proxCardLabel') as string;
    form.setFieldsValue({
      passwordLabel: passwordLabel || passwordDefault,
      usernameLabel: usernameLabel || usernameDefault,
      proxCardLabel: proxCardLabel || proxcardDefault,
    });

    void form.validateFields().then(values => {
      void updatePreferences(
        dispatch,
        values.passwordLabel,
        values.usernameLabel,
        values.proxCardLabel,
        values.orgName,
        uploadedUrl,
      );
      setSaveDisabled(true);
      setIsModalVisible(false);
    });
  };

  const setURL = (url: string): void => {
    setUploadedUrl(url);
    setIsDirty(true);
    setSaveDisabled(false);
  };
  if (isLoading) {
    return (
      <div className={classes.spinner}>
        <Spin />
      </div>
    );
  }

  return (
    <SettingsPageLayout
      title={
        <>
          <SaveButton
            data-testid="save-button"
            onClick={() => {
              validateAndUpdate();
            }}
            disabled={isSaveDisabled}
          />
          <CancelButton
            onClick={() => {
              resetForm();
            }}
            disabled={!isDirty}
          />
          <SaveDiscardModal
            title={t('appearance.organization.save-discard-modal.title')}
            cancelText={t('appearance.organization.save-discard-modal.discard')}
            okText={t('actions.save')}
            content={t('appearance.organization.save-discard-modal.content')}
            open={isModalVisible}
            onSave={() => {
              validateAndUpdate();
            }}
            onDiscard={() => {
              resetForm();
            }}
          />
          <HelpButton />
        </>
      }
    >
      <Form {...formItemLayout} form={form} layout="vertical" name="register">
        <div>
          <Row style={{ display: 'none' }}>
            <Col>
              <div className="h3-header">
                {t('appearance.organization.customization.title')}
              </div>
              <p className="primary-body" style={{ display: 'none' }}>
                {t('appearance.organization.customization.description')}
              </p>
            </Col>
          </Row>
          <Row gutter={10} wrap={true}>
            <Col flex="1">
              <LogoUploader
                storedLogoUrl={data?.logoUrl}
                onUploadCallback={setURL}
                reset={reset}
              />
            </Col>
            <Col flex="1">
              <div style={{ display: 'none' }}>
                <Form.Item
                  name="orgName"
                  label={t('appearance.organization.customization.name.label')}
                >
                  <InputBox
                    data-testid="appearance-form--organization-name"
                    style={{
                      width: '70%',
                    }}
                    type="text"
                    size="small"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          {EPCS_ENABLED && (
            <>
              <Row
                gutter={10}
                wrap={true}
                className={classes.rowWithTopSpacing}
              >
                <Col>
                  <div className="h3-header">
                    {t('appearance.organization.authentication.title')}
                  </div>
                  <p>
                    {t('appearance.organization.authentication.description')}
                  </p>
                </Col>
              </Row>
              <Row gutter={10} wrap={true} onChange={handleChangeText}>
                <Col flex="1">
                  <Form.Item
                    data-testid="appearance-form--username-label"
                    name="usernameLabel"
                    label={t(
                      'appearance.organization.authentication.username.label',
                    )}
                    rules={[
                      {
                        message: t(requiredMsgKey),
                      },
                    ]}
                  >
                    <InputBox
                      data-testid="appearance-form--username"
                      maxLength={24}
                      style={{
                        width: '496px',
                      }}
                      size="small"
                      type="text"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} wrap={true} onChange={handleChangeText}>
                <Col flex="1">
                  <Form.Item
                    data-testid="appearance-form--password-label"
                    name="passwordLabel"
                    label={t(
                      'appearance.organization.authentication.password.label',
                    )}
                    rules={[
                      {
                        message: t(requiredMsgKey),
                      },
                    ]}
                  >
                    <InputBox
                      data-testid="appearance-form--password"
                      maxLength={24}
                      style={{
                        width: '496px',
                      }}
                      size="small"
                      type="text"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={10} wrap={true} onChange={handleChangeText}>
                <Col flex="1">
                  <Form.Item
                    data-testid="appearance-form--prox-card-label"
                    name="proxCardLabel"
                    label={t(
                      'appearance.organization.authentication.prox-card.label',
                    )}
                    rules={[
                      {
                        message: t(requiredMsgKey),
                      },
                    ]}
                  >
                    <InputBox
                      data-testid="appearance-form--prox-card"
                      maxLength={24}
                      style={{
                        width: '496px',
                      }}
                      type="text"
                      size="small"
                    />
                  </Form.Item>
                </Col>
                <Col flex="1"></Col>
              </Row>
            </>
          )}
        </div>
      </Form>
    </SettingsPageLayout>
  );
};

export default Appearance;
